import request from '@/utils/request';

// 分类删除
export function cateDel(id) {
  return request({
    url: `/article-class/delete?ids=${id}`,
    method: 'delete',
  });
}

// 分类修改/新增
export function cateSave(data) {
  return request({
    data,
    url: '/article-class/save',
    method: 'post',
  });
}

// 文章删除
export function articleDel(id) {
  return request({
    url: `/article/delete?ids=${id}`,
    method: 'delete',
  });
}

// 文章修改/新增
export function articleSave(data) {
  return request({
    data,
    url: '/article/save',
    method: 'post',
  });
}

// 合作企业删除
export function linkDel(id) {
  return request({
    url: `/site-link/delete?ids=${id}`,
    method: 'delete',
  });
}

// 合作企业修改/新增
export function linkSave(data) {
  return request({
    data,
    url: '/site-link/save',
    method: 'post',
  });
}

// banner详情
export function bannerDetail() {
  return request({
    url: '/site-banner/detail',
    method: 'get',
    noLoading: true,
  });
}

// banner修改/新增
export function bannerSave(data) {
  return request({
    data,
    url: '/site-banner/save',
    method: 'post',
  });
}
