<template>
  <div>
    <lz-search
      @search-btn-click="handleCommonBtnCLick"
      :search="search">
    </lz-search>
    <lz-table-list
      :loading="loading"
      :list="list"
      :table="table">
    </lz-table-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      title="完善banner"
      ref="lzDialog">
      <lz-form :form="form" :col="3" ref="lzForm"></lz-form>
    </lz-dialog>
  </div>
</template>
<script>
import { bannerDetail, bannerSave } from '@/api/frontend';

export default {
  data() {
    return {
      search: [],
      config: {
        url: '/site-banner/detail',
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '名称', prop: 'name' },
        { name: '图片', prop: 'pic', type: 'image' },
        { name: '类型', prop: 'typeName' },
      ],
      form: [
        // {
        //   fieldName: 'banner_ids',
        //   type: 'upload',
        //   name: '电脑端图组(1940 * 1080)',
        //   value: [],
        //   multiple: true,
        //   required: false,
        //   width: '100%',
        // },
        // {
        //   fieldName: 'mobile_banner_ids',
        //   type: 'upload',
        //   name: '手机端图组(750 * 1080)',
        //   value: [],
        //   multiple: true,
        //   required: false,
        //   width: '100%',
        // },
        {
          fieldName: 'particle_banner_ids',
          type: 'upload',
          name: '粒子banner(1942 * 890)',
          value: [],
          multiple: true,
          required: false,
          width: '100%',
        },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      loading: false,
      list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      bannerDetail().then((response) => {
        this.loading = false;
        // const banner = response.data.banner.map((r) => ({
        //   ...r,
        //   type: 'pc',
        //   typeName: '电脑端',
        // }));
        // const moibleBanner = response.data.mobile_banner.map((r) => ({
        //   ...r,
        //   type: 'mobile',
        //   typeName: '手机端',
        // }));
        const particleBanner = response.data.particle_banner.map((r) => ({
          ...r,
          type: 'particle',
          typeName: '粒子',
        }));

        this.list = [...particleBanner];
      });
    },
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          const response = await bannerSave(params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzDialog.dialogVisible = false;
          this.getData();
        }
          break;
        case 'add':
          this.handleDialogForm();
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm() {
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: {
            // banner_ids: this.list.filter((r) => r.type === 'pc'),
            // mobile_banner_ids: this.list.filter((r) => r.type === 'mobile'),
            particle_banner_ids: this.list.filter((r) => r.type === 'particle'),
          },
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
